import React, { useEffect, useState } from "react";
import Util from '../../helpers/util'
import {Drip7Slider} from "../drip7_slider";

export const AnswerTypeSlider = React.forwardRef((props, ref) => {
    const labels = props.answer.labels || ['', '']
    const range = props.answer.range || [0, 10]

    const { value, answer, readOnly, onChanged } = props;

    const [state, setState] = useState({
        user_answer: (value != undefined)? value: "",
        submitted: false,
    })
    const {user_answer, submitted} = state

    React.useImperativeHandle(ref, () => ({
        reset: () => {
            setState(prev => ({...prev,
                user_answer: "",
                submitted: false,
            }))
        },
        attemptAnswer: () => {
            if ( user_answer == '' ) {
                return false;
            }

            handleSubmitAnswer( user_answer )
            return true;
        }
    }));

    const handleChange = (e) => {
        if ( readOnly ) {
            return
        }

        setState(prev => ({...prev,
            user_answer: e.target.value,
        }))
    }

    const handleSubmitAnswer = (value) => {
        //Clean the value
        value = ( value != "" )? Util.xint(value): (range[1] - range[0]) / 2

        //Find the bin
        let bin = -1
        if ( 'answers' in props.answer ) {
            Object.entries(props.answer.answers).forEach( ([k, v]) => {
                if ( bin == -1 && value >= v[0] && value <= v[1] ) {
                    bin = k
                }
            })
        }

        // Answer always correct if user moves the slider
        const ans_correct = ('correct' in answer && answer.correct != undefined && answer.correct != null)? answer.correct: bin
        //const ans_val = ('answers' in answer && ans_correct >= 0 && ans_correct < answer.answers.length)? answer.answers[ans_correct]: ""
        const is_correct = (ans_correct == -1) || (ans_correct == bin)
        onChanged( value, is_correct, bin)

        setState(prev => ({...prev,
            user_answer: value,
            submitted: true,
        }))
    }

    return (
        <div className='w-1/2'>
            <Drip7Slider
                value={user_answer}
                min={range[0]} max={range[1]}
                display={() => ''}
                className="max-w-full"
                onChange={handleChange}
                />
            <div className="w-full inline-flex justify-between">
                <div className='text-normal font-semibold text-gray-600'>
                    {labels[0]}
                </div>
                <div className='text-normal font-semibold text-gray-600'>
                    {user_answer}
                </div>
                <div className='text-normal font-semibold text-gray-600'>
                    {labels[1]}
                </div>
            </div>
        </div>

    );
});
