export const MemberRole = {
    NORMAL: "Normal",
    ADMIN: "Admin",
    SUPER_ADMIN: "Super Admin",
}

export const OffsetMode = {
    DAYS:   "Days",
    MONTHS: "Months",
    DATE:   "Date",
}

export const ContentType = {
    Text: "Text",
    Image: "Image",
    Video: "Video",
    Phish: "Phish",
    Document: "Document",
};

export const AnswerType = {
    Bool: "Boolean",
    Multiple: "Multiple",
    Buttons: "Buttons",
    Fill: "Fill",
    Slider: "Slider",
    Checkbox: "Checkbox",
    AiChat: "Ai Chat",
    Accept: "Accept",
    QRCode: "QR Code",
    None: "None",
};

export const BadgeCriteria = {
    CORRECT_ANSWERS: "Correct Answers",
    TOTAL_ANSWERS: "Total Answers",
    TOTAL_POINTS: "Total Points",

    STREAK: "Streak",
    CORRECT_STREAK: "Correct Streak",

    WIN_MONTHLY: "Win Monthly",
    WIN_QUARTERLY: "Win Quarterly",

    LOGIN_30_DAYS: "Login 30 Days",

    APP_DOWNLOADED: "App Downloaded",
};

export const Languages = {
    English: "en-us",
    Spanish: "es",
    FrenchCA: "fr-ca",
    French: "fr",
    Italian: "it",
    Dutch: "nl",
    German: "de",
    Norwegian: "no",
    Swedish: "sv",
    Finnish: "fi",
    Japanese: "ja",
    Portuguese: "pt",
    ChineseSimp: "zh-cn",
    ChineseTrad: "zh-tw",
};

export const QueueDelivery = {
    Drip: "Drip",
    Splash: "Splash",
    Phish: "Phish"
};

export const CurriculumUsage = {
    CURRICULUM: "Curriculum",
    CAMPAIGN: "Campaign",
    POLICY: "Policy",
};

export const AuthenticationType = {
    USER_PWD: 1,
    AZURE_SSO: 2,
    SAML: 3
};

export const PushTokenState = {
    NONE: 1,
    SYNCING_TOKEN: 2,
    TOKEN_SUCCESS: 3,
    TOKEN_FAILED: 4
};

export const ReportType = {
    REPORT_OVERVIEW: "Overview report",
    REPORT_COURSE: "Course report",
    REPORT_USERS: "User report",
    REPORT_USER_ACTIVITY: "User activity report",
    REPORT_CURRICULUM: "Curriculum report",
    REPORT_BADGE: "Badge report",
    REPORT_QUESTION: "Question report",
    REPORT_POINTS: "Points report",
};

export const ReportDelivery = {
    WEEKLY: "Weekly",
    MONTHLY: "Monthly",
};

export const DataRange = {
    LAST_REPORT: "Last report",
    YEAR_TO_DATE: "Year to date",
    ALL: "All",
};

export const Access = {
    READ: "Read",
    WRITE: "Write",
    EXEC: "Exec",
    ALL: "All",
}

export const Notification = {
    NONE: "None",
    DAILY: "Daily",
    WEEKLY: "Weekly",
}

export const QueueDeliveryUi = {
    WEEKDAY:    "Every Weekday",
    EVERY_DAY:  "Every Day",
    CUSTOM:     "Custom",
}

export const QueuePhishDelivery = {
    DELIVERY_SPAN: "Delivery Span",
    CUSTOM:        "Custom",
}

export const BlockMode = {
    SPECIFIC:   "Specific",
    GUIDED:     "Guided",
}

export const ViewMode = {
    LIST:       "List",
    GRAPHICAL:  "Graphical",
    HISTORIC:   "Inactive",
}

export const NavigationPages = {
    DASHBOARD:          "/dashboard",
    LEADERBOARD:        "/leaderboard",
    TIER:               "/tier",
    BADGE_STATUS:       "/badge-status",
    QUESTION_ARCHIVE:   "/question-archive",
    PROFILE_SETTINGS:   "/profile-settings",
    DRIP:               "/drip",
    CURRICULUM:         "/curriculum",
    WHATSNEXT:          "/whats-next",
    CUSTOM:             "",
}

export const CurriculumTypes = {
    NORMAL:             "Normal",
    ASSIGNABLE_SPLASH:  "Assignable Splash",
    ASSIGNABLE_DRIP:    "Assignable Drip",
}

export const ManualPointActions = {
    ADD:    "Add",
    MIN:    "Min",
    MAX:    "Max",
    ASSIGN: "Assign",
}

export const UserSelectionLogic = {
    ALL:        "All",
    INACTIVE:   "Inactive",
    BEHIND:     "Behind",
    IN_PROGRESS: "In Progress",
    COMPLETED:  "Completed",
}

export const Behavior = {
    NORMAL:             "Normal",
    CONTENT_MODULE:     "Content Module",
    PHISHING_MODULE:    "Phishing Module",
}

export const PackageFrequency = {
    MONTHLY:    "price_1month",
    YEARLY:     "price_12month",
    YEARLY_3:   "price_36month",
}

import BadgeWood from "../assets/images/badges/badge_wood.png";
import BadgeSteel from "../assets/images/badges/badge_steel.png";
import BadgeBronze from "../assets/images/badges/badge_bronze.png";
import BadgeSilver from "../assets/images/badges/badge_silver.png";
import BadgeGold from "../assets/images/badges/badge_gold.png";
import BadgePlatinum from "../assets/images/badges/badge_platinum.png";
import BadgeDiamond from "../assets/images/badges/badge_diamond.png";

export const TierBadge = {
    Diamond:    BadgeDiamond,
    Platinum:   BadgePlatinum,
    Gold:       BadgeGold,
    Silver:     BadgeSilver,
    Bronze:     BadgeBronze,
    Steel:      BadgeSteel,
    Wood:       BadgeWood,
}

export const TierNumber = {
    Diamond:    1,
    Platinum:   2,
    Gold:       3,
    Silver:     4,
    Bronze:     5,
    Steel:      6,
    Wood:       7,
}

export const TierNames = [
    "Diamond",
    "Platinum",
    "Gold",
    "Silver",
    "Bronze",
    "Steel",
    "Wood",
]
