import React, { useEffect, useState } from "react";

import { useStore } from "../../store";
import { AcademicCapIcon, DocumentCheckIcon } from "@heroicons/react/24/outline";
import Util from "../../helpers/util";
import Language from "../../helpers/language";


export const QueueMspTab = (props) => {
    const { tenant_uid, tenants, showToast, onContext } = props

    const {usr_info} = useStore(x => x)

    const [state, setState] = useState({
        queue_lookup: {},
        user_lookup: {},
        answer_lookup: {},
    })
    const { queue_lookup, user_lookup, answer_lookup } = state

    useEffect(() => {
        Util.fetch_js('/dashboard/msp/', { tenant_uid: usr_info?.tenant?.uid },
            js => {
                console.log(js.answered)
                setState(prev => ({...prev,
                    queue_lookup: js.queues,
                    user_lookup: js.users,
                    answer_lookup: js.answered,
                }));
            }, showToast)
    }, [])

    const engaged = user_info => {
        const perc = user_info?.percentage || undefined
        if ( perc === undefined || perc === null || perc === 0 ) {
            return (
                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                    {Language.getTitleCase('none')}
                </span>
            )
        }

        if ( perc >= 90 ) {
            return (
                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    {Language.getTitleCase('fantastic')} {perc}%
                </span>
            )
        }
        else if ( perc >= 65 ) {
            return (
                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    {Language.getTitleCase('Mostly')} {perc}%
                </span>
            )
        }
        else if ( perc >= 50 ) {
            return (
                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    {Language.getTitleCase('moderate')} {perc}%
                </span>
            )
        }
        else {
            return (
                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                    {Language.getTitleCase('unengaged')} {perc}%
                </span>
            )
        }
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8 mt-4">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold text-gray-900">
                        Tenants
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        All of your tenants and their engagement.
                    </p>
                </div>
            </div>

            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 text-left text-sm font-semibold text-gray-900">
                                    Tenant
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Answers last /wk
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Training
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {Language.getSentenceCase('engagement')}
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {tenants.map((tenant, idx) => (
                                <tr key={`tenant_row_${idx}`} className='cursor-pointer'
                                    onClick={() => onContext(tenant.uid)}>
                                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                        <div className="flex items-center">
                                            <div className="size-11 shrink-0 bg-gray-50">
                                                <img alt="" src={tenant.full_logo} className="size-11 rounded-full" />
                                            </div>
                                            <div className="ml-4">
                                                <div className="font-medium text-gray-900">{tenant.name}</div>
                                                <div className="mt-1 text-gray-500 flex flex-row">
                                                    {tenant.has_content_creation &&
                                                        <AcademicCapIcon className="size-4 text-drip7"/>
                                                    }
                                                    {tenant.has_document_acceptance &&
                                                        <DocumentCheckIcon className="ml-2 size-4 text-drip7"/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                        {answer_lookup[tenant.uid] || Language.getTitleCase('none')}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                        {queue_lookup[tenant.uid] || Language.getTitleCase('none')}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                        {engaged( user_lookup[tenant.uid] )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

