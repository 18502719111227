import React, { useEffect, useState } from "react";

import { DashboardTenantTab } from "./dashboard_tenant_tab";
import Language from "../../helpers/language";
import { DashboardMspTab } from "./dashboard_msp_tab";
import { useStore } from "../../store";


export const Dashboard = (props) => {
    const { tenant_uid, tenants, showToast, onContext } = props

    const [state, setState] = useState({
        selected_idx: 0,
    })
    const { selected_idx } = state

    const { usr_info } = useStore( x => x )

    useEffect(() => {
        props.onPathChange("dashboard");
    }, [])

    const handleChange = (e) => {
        setState(prev => ({ ...prev,
            [e.target.id]: e.target.value
        }))
    }

    const handleContext = (uid) => {
        onContext(uid)
        setState(prev => ({...prev, selected_idx: 0 }))
    }

    const { is_msp } = usr_info.tenant

    return (
        <div className='curriculum main-modal'>
            <div className='central__main central__main--management'>
                <nav className='central__nav'>
                    <ul className="central__nav-list">
                        <li className={"central__nav-item central__nav-item--small " + (selected_idx === 0? "central__nav-item--selected": '')}
                            onClick={() => setState(prev => ({...prev, selected_idx: 0 }))}>
                            <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill='var(--drip7-primary-color)' d="M22.2187 5.39003C23.332 4.86999 24.5937 4.86999 25.707 5.39003L41.9609 12.8935C42.5547 13.1906 43 13.7849 43 14.4536C43 15.1965 42.5547 15.7908 41.9609 16.088L25.707 23.5914C24.5937 24.1114 23.332 24.1114 22.2187 23.5914L5.96484 16.088C5.37109 15.7908 5 15.1965 5 14.4536C5 13.7849 5.37109 13.1906 5.96484 12.8935L22.2187 5.39003ZM41.9609 22.4027C42.5547 22.6999 43 23.2942 43 23.9629C43 24.7058 42.5547 25.3001 41.9609 25.5973L25.707 33.1007C24.5937 33.6207 23.332 33.6207 22.2187 33.1007L5.96484 25.5973C5.37109 25.3001 5 24.7058 5 23.9629C5 23.2942 5.37109 22.6999 5.96484 22.4027L9.97266 20.5455L21.2539 25.7458C22.9609 26.563 24.9648 26.563 26.6719 25.7458L37.9531 20.5455L41.9609 22.4027ZM26.6719 35.2551L37.9531 30.0547L41.9609 31.912C42.5547 32.2092 43 32.8035 43 33.4721C43 34.2151 42.5547 34.8094 41.9609 35.1065L25.707 42.61C24.5937 43.13 23.332 43.13 22.2187 42.61L5.96484 35.1065C5.37109 34.8094 5 34.2151 5 33.4721C5 32.8035 5.37109 32.2092 5.96484 31.912L9.97266 30.0547L21.2539 35.2551C22.9609 36.0723 24.9648 36.0723 26.6719 35.2551Z" />
                            </svg>
                            {Language.getTitleCase('dashboard')}
                        </li>

                        {is_msp &&
                        <li className={ "central__nav-item central__nav-item--wide " + (selected_idx === 1 ? "central__nav-item--selected" : "") }
                            onClick={() => setState(prev => ({...prev, selected_idx: 1 }))}>
                            <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill='var(--drip7-primary-color)' d="M44.8047 15.4863L24.9063 8.26801C23.9219 7.91066 22.8672 7.91066 21.8828 8.26801L2.125 15.4863C1.42188 15.7722 1 16.4154 1 17.1301C1 17.9162 1.42188 18.5595 2.125 18.8453L6.34375 20.3462C5.5 21.4897 4.86719 22.7761 4.58594 24.2055C3.8125 24.5628 3.25 25.349 3.25 26.2781C3.25 26.9927 3.53125 27.636 4.02344 28.0648L2.33594 38.7136C2.19531 39.4283 2.75781 40 3.39063 40H7.53906C8.17188 40 8.73438 39.4283 8.59375 38.7136L6.90625 28.0648C7.39844 27.636 7.75 26.9927 7.75 26.2781C7.75 25.5634 7.32813 24.9202 6.83594 24.5628C7.11719 23.2764 7.75 22.1329 8.59375 21.2038L21.9531 26.0637C22.9375 26.421 23.9922 26.421 24.9766 26.0637L44.8047 18.8453C45.5078 18.5595 46 17.9162 46 17.1301C46 16.4154 45.5078 15.7722 44.8047 15.4863ZM25.6797 28.2077C24.9766 28.4936 24.2031 28.565 23.5 28.565C22.7266 28.565 21.9531 28.4936 21.25 28.2077L10.9844 24.4913L10 34.7113C10 37.6415 15.9766 40 23.5 40C30.8828 40 37 37.6415 37 34.7113L35.9453 24.4913L25.6797 28.2077Z" />
                            </svg>
                            {Language.getTitleCase('msp')}
                        </li>
                        }
                    </ul>
                </nav>

                {selected_idx === 0 &&
                <DashboardTenantTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                    />
                }
                {selected_idx === 1 &&
                    <DashboardMspTab
                        tenant_uid={tenant_uid}
                        tenants={tenants}
                        showToast={showToast}
                        onContext={handleContext}
                    />
                }
            </div>
        </div>
    )
}
