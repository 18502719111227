import React, { useRef, useEffect, useState } from "react";
import Util from "../../helpers/util";
import  FullBlue  from "../../assets/images/user-portal/drip-full-blue.svg";
import {
    Notification, OffsetMode,
    QueueDelivery,
    ViewMode
} from "../../helpers/consts";
import {LockClosedIcon} from "@heroicons/react/24/solid";


export const QueueTrackGraphicalWidget = (props) => {
    const { queue_track, queues, selected, isPolicy, onChange, showToast, onUpdateQueues, onShowQueue, onRefresh } = props

    const [state, setState] = useState({
        drag_uid: null,
        drag_x: 0,
        pri_drag: useRef(),
        vis_ref: useRef(),
    })
    const { drag_uid, drag_x, pri_drag, vis_ref } = state

    const unbound = (queue_track.uid.toLowerCase() == 'unbound')

        //set a default ref?
    if ( pri_drag.current == null ) {
        pri_drag.current = {src: null, dst: null}
    }

    const active_queues = queues.filter( x => x.active )

    const colors = [
        '#1be7ff',
        '#937fd9',
        '#ff5714',
        '#5dff6b',
        '#ffbb16',
        '#f3ff0b',
    ]

    //Create my counts and target exports
    let total_count = 0
    let qcs = {}
    let q_dow = {}
    active_queues.forEach( x => {
        //Assign the scheduling
        q_dow[x.uid] = (x.schedules.length == 7 && x.delivery != QueueDelivery.Splash) ? x.schedules.map( x => Math.max( x.count, 0 ) ): [1,1,1,1,1,1,1]

        //Ensure the schedule count has a value
        let schedule_count = 0
        q_dow[x.uid].forEach( x => schedule_count += x )

        //If we are phish we just want the schedule count and thats it
        if ( x.delivery == QueueDelivery.Phish ) {
            qcs[x.uid] = Math.max( schedule_count, 1 )
            return
        }

        //Only account for the count if we have scheduling
        const count = (schedule_count > 0)? x.curriculum.delivery_count: 0

        //Set my count value
        qcs[x.uid] = count
        total_count += count
    })

    //Calculate the delivery
    let queue_visualized = active_queues.map( x => [])
    let total_days = 0
    const max_daily = Math.max( ('max_daily' in queue_track)? queue_track.max_daily: 1000000, 1 )
    for ( total_days = 0; total_count > 0; total_days++ ) {
        let today = 0

        //Calculate who "hits" on this day
        Object.entries(active_queues).forEach( ([q_idx, x]) => {
            if ( x.delivery == QueueDelivery.Phish ) {
                return
            }

            let visible = false

            //Days are only assignable if we have cleared the queue offset
            const offset = (x.offset_mode == OffsetMode.MONTHS)? Util.getMonthDoy(x.offset): x.offset

            //Have we found our range yet?
            if ( offset <= total_days ) {
                const count = Math.min( qcs[x.uid], q_dow[x.uid][total_days % 7] )
                for ( let i = 0; today < max_daily && i < count; i++ ) {
                    visible = true
                    today += 1
                    qcs[x.uid] -= 1
                    total_count -= 1
                }
            }

            //Check if we can increase our width, or need to make a new object to visualize
            const qv = queue_visualized[q_idx]
            const qv_last = qv.length - 1
            if ( qv_last < 0 || qv[qv_last].visible != visible ) {
                queue_visualized[q_idx].push({
                    draggable: true,//x.offset_mode == OffsetMode.DAYS,
                    visible,
                    start: total_days,
                    width: 1,
                })
            }
            else {
                queue_visualized[q_idx][qv_last].width++
            }
        })
    }

    //Ensure our total days is at least one year long
    total_days = Math.max( total_days, Util.daysInYear() )

    //Pad the end of the blocks
    queue_visualized.forEach(x => {
        const width = x.reduce( (acc, y) => acc + y.width, 0 )
        if ( width < total_days ) {
            x.push({
                draggable: false,
                visible: false,
                start: width,
                width: total_days - width,
            })
        }
    })
    //console.log(queue_visualized)

    //Calculate the date margin we should allow on the right side
    const adjusted_total = Util.daysInYear() / total_days

    //Add all the phish
    Object.entries(active_queues).forEach( ([q_idx, x]) => {
        if ( x.delivery != QueueDelivery.Phish ) {
            return
        }

        //Add the phish entry
        x.campaigns.forEach( c => {
            const entries = c.day_offsets.map( d => ({
                draggable: false,
                visible: true,
                start: d,
                width: qcs[x.uid],
            }))

            queue_visualized[q_idx] = queue_visualized[q_idx].concat( entries )
        })
    })

    const handleSelect = (checked, uid) => {
        let { selected } = props

        if (checked) {
            selected[uid] = true;
        }
        else if (uid in selected) {
            delete selected[uid];
        }

        onChange( { target: { id: "selected", value: selected }})
    }

    const handleStartDrag = (e) => {
        let drag_x = e.clientX
        const drag_uid = e.target.id

        //If the Queue has an offset, we want to offset our pixels to avoid a "Jump" on first move
        const width = vis_ref.current.getBoundingClientRect().width
        active_queues.forEach( x => {
            if ( x.uid == drag_uid ) {
                const scale = (x.offset_mode == OffsetMode.MONTHS)? 11: total_days
                const adj_width = (x.offset_mode == OffsetMode.MONTHS)? width * adjusted_total: width
                drag_x -= Math.round( Math.max( (adj_width * x.offset) / scale, 0 ))
            }
        })

        setState(prev => ({ ...prev,
            drag_uid,
            drag_x,
        }))
    }

    const handleMouseMove = (e) => {
        if ( drag_uid == null ) {
            return
        }

        const delta = (e.clientX - drag_x)
        let width = vis_ref.current.getBoundingClientRect().width

        let q = active_queues.map( x => ({ ...x }))
        q.forEach( x => {
            if ( x.uid == drag_uid ) {
                const org_offset = x.offset
                const adj_width = (x.offset_mode == OffsetMode.MONTHS)? width * adjusted_total: width
                const scale = (x.offset_mode == OffsetMode.MONTHS)? 11: total_days

                //console.log(`${delta} / ${adj_width} = ${delta / adj_width}`)
                x.offset = Math.round( Math.max( scale * (delta / adj_width), 0 ))

                //Cap the dates
                if ( x.offset_mode == OffsetMode.DATE || x.offset_mode == OffsetMode.DAYS ) {
                    x.offset = Math.min( x.offset, Util.daysInYear() - 1)
                }
                else if ( x.offset_mode == OffsetMode.MONTHS ) {
                    x.offset = Math.min( x.offset, 11 )
                }

                //Hack because drag and drop to zero doesn't work relibably
                if ( x.offset == 0 && org_offset > 0 ) {
                    Util.fetch_js('/queue/modify/', { queue_uid: drag_uid, offset: x.offset },
                        js => {},
                        (err, code) => {
                            showToast( err, "failure")
                        })
                }
            }
        })

        onUpdateQueues( q )
    }

    const handleStopDrag = (e) => {
        let offset = null
        active_queues.forEach(x => {
            if ( x.uid == drag_uid ) {
                offset = x.offset
            }
        })

        //Update?
        if ( offset != null ) {
            Util.fetch_js('/queue/modify/', { queue_uid: drag_uid, offset },
                js => {},
                (err, code) => {
                    showToast( err, "failure")
                })
        }

        setState(prev => ({ ...prev,
            drag_uid: null,
            drag_x: 0,
        }))
    }

    const handlePriorityDragStart = (e) => {
        pri_drag.current.src = e.target.id
    }

    const handlePriorityDragAllow = (e) => {
        pri_drag.current.dst = e.target.id
        e.preventDefault()
    }

    const handlePriorityDragDrop = (e) => {
        const { src, dst } = pri_drag.current

        //Find the indexes
        let s_idx = -1
        let d_idx = -1
        if ( dst != null && src != null ) {
            for ( let i = 0; i < active_queues.length; i++ ) {
                if ( active_queues[i].uid == src ) {
                    s_idx = i
                }
                if ( active_queues[i].uid == dst ) {
                    d_idx = i
                }
            }
        }

        //Reset my ref
        pri_drag.current = { src: null, dst: null }

        //Flip stuff around
        if ( s_idx < 0 || d_idx < 0 || unbound ) {
            return
        }

        //Close the src and the queue
        let new_queues = active_queues.map( x => ({ ...x }))
        const move = { ...active_queues[s_idx] }

        //Remove the src
        new_queues.splice( s_idx, 1 )

        //Find where the target lives, if not found, Append
        if ( d_idx >= 0 ) {
            new_queues.splice(d_idx, 0, move)
        }
        else {
            new_queues.push( move )
        }

        //Update the server
        const queue_track_uid = queue_track.uid
        const queue_uids = new_queues.map( x => x.uid )
        Util.fetch_js('/queue_track/modify/', { queue_track_uid, queue_uids },
            js => {
                onRefresh()
            },
            (err, code) => {
                showToast( err, "failure")
            })
    }

    return (
        <div className="queue-track-graphical">
            <div style={{
                display: 'flex',
                marginLeft: '142px',
                width: `calc( ${adjusted_total * 100.0}% - ${142 * adjusted_total}px)`,
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                height: '32px',
                }} className="management__table-text">
                {Object.entries(Util.shortMonths()).map( ([k,x]) =>
                    <div key={`date_title_${k}`}>{x}</div>
                )}
                <div>&nbsp;</div>
            </div>
            {Object.entries(active_queues).map(([qk,queue]) => (
                <div key={`qt_key_${qk}`} className="queue-track-graphical--row">
                    <div id={queue.id} className='d7__checkbox-container' style={{width: '32px'}}>
                        <input
                            id={queue.uid}
                            className='d7__checkbox'
                            type='checkbox'
                            aria-label="checkbox"
                            checked={ queue.uid in selected }
                            onChange={e => handleSelect( e.target.checked, queue.uid )} />
                        <span className='checkmark'></span>
                    </div>
                    <div className="management__table-text queue-track-graphical--name"
                         id={queue.uid}
                         onClick={() => onShowQueue( queue )}
                         draggable={!unbound}
                         onDragStart={handlePriorityDragStart}
                         onDragEnter={handlePriorityDragAllow}
                         onDragOver={handlePriorityDragAllow}
                         onDragEnd={handlePriorityDragDrop}>
                        {queue.offset_mode == OffsetMode.DAYS &&
                        <label id={queue.uid} className="pointer">
                            {(queue.offset > 0)? `+${queue.offset}  `: ''}{queue.name}
                        </label>
                        }
                        {queue.offset_mode == OffsetMode.MONTHS &&
                            <label id={queue.uid}>
                                {Util.toShortMonth(queue.offset)}: {queue.name}
                            </label>
                        }
                        {queue.offset_mode == OffsetMode.DATE &&
                            <label id={queue.uid}>
                                {Util.doyToMonthDay(queue.offset).short_mon} {Util.addIst( Util.doyToMonthDay(queue.offset).day)} : {queue.name}
                            </label>
                        }
                        {!isPolicy &&
                        <div id={queue.uid} className="inline-flex queue-track-graphical--delivery pointer">
                            {queue.is_managed &&
                            <LockClosedIcon className="h-4 w-4 mr-1 text-drip7-action" />
                            }
                            {queue.delivery}
                        </div>
                        }
                    </div>
                    <div className="queue-track-graphical--visualize"
                         ref={(qk == 0)? vis_ref: null}>
                        <hr style={{ width: '100%' }} />
                        {Object.entries(queue_visualized[qk]).map(([vk,entry]) => {
                            //Build my style object
                            let style = {
                                left: `${(100.0 * entry.start) / total_days}%`,
                                width: `${(100.0 * entry.width) / total_days}%`,
                                backgroundColor: colors[qk % colors.length],
                                opacity: (entry.visible)? 1: 0,
                            }

                            if ( entry.visible && isPolicy ) {
                                delete style.width
                                delete style.backgroundColor
                                return <img key={`qv_key_${qk}_${vk}`}
                                            id={queue.uid}
                                            className="queue-track-graphical--visualize--entry w-6 h-6 -ml-3 cursor-pointer z-10"
                                            style={style}
                                            src={FullBlue}
                                            draggable={entry.draggable}
                                            onDragStart={handleStartDrag}
                                            onDragOver={handleMouseMove}
                                            onDragEnd={handleStopDrag}
                                />
                            }

                            //Return out a div block
                            return <div key={`qv_key_${qk}_${vk}`}
                                        id={queue.uid}
                                        className="queue-track-graphical--visualize--entry"
                                        style={style}
                                        draggable={entry.draggable}
                                        onDragStart={handleStartDrag}
                                        onDragOver={handleMouseMove}
                                        onDragEnd={handleStopDrag}
                            />
                        })}
                    </div>
                </div>
            ))}

            {!unbound &&
            <div className="queue-track-graphical--row">
                <div className="management__table-text queue-track-graphical--name"
                     id={""}
                     draggable={true}
                     onDragEnter={handlePriorityDragAllow}
                     onDragOver={handlePriorityDragAllow}
                     onDragEnd={handlePriorityDragDrop}>
                    &nbsp;
                </div>
            </div>
            }
        </div>
    );
}
