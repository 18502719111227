import React, { useEffect, useState } from "react";

import { useStore } from "../../store";
import { ToggleWidget } from "../../components/admin/toggle_widget";
import { LeaderBoardCard } from "../../cards/leaderboard_card";
import { UserProgressCard } from "../../cards/user_progress_card";
import { ContentCard } from "../../cards/content_card";
import { PhishCard } from "../../cards/phish_card";
import { QuestionActivityCard } from "../../cards/question_activity_card";
import { UserActivityCard } from "../../cards/user_activity_card";
import { ActiveQueueCard } from "../../cards/active_queue_card";
import { CountCard } from "../../cards/count_card";
import { TotalHoursCard } from "../../cards/total_hours_card";
import { Languages } from "../../helpers/consts";
import Language from "../../helpers/language";
import SETTINGS from "../../assets/images/shared/settings.svg"
import { PolicyCard } from "../../cards/policy_card";


export const DashboardTenantTab = (props) => {
    const { tenant_uid, tenants, showToast } = props

    const { usr_info } = useStore(x => x)

    const [state, setState] = useState({
        show_widget_settings: false,
        show_single: true,
    })
    const { show_widget_settings, show_single } = state

    const handleChange = (e) => {
        setState(prev => ({ ...prev,
            [e.target.id]: e.target.value
        }))
    }
    
    const { is_msp } = usr_info.tenant
    const tenant = tenants.find( x => x.uid == tenant_uid ) || {}
    const has_phishing = is_msp || tenant.has_phishing
    const has_acceptance = tenant.has_document_acceptance

    return (
        <>
            {tenants.length > 1 && usr_info.tenant_uid == tenant_uid &&
            <div className='admin-header-wrap mt-4 mr-4 -mb-8'>
                <div className='widgets-settings-button'
                    onClick={() => handleChange({ target: { id: 'show_single', value: !show_single }})}>
                    <img src={SETTINGS} alt='settings button' />
                    <h4>
                        {(show_single)? Language.getTitleCase('my tenant'): Language.getTitleCase('aggregate')}
                    </h4>
                </div>
            </div>
            }

            <div className='admin-card__container'>
                <CountCard
                    usr_info={usr_info}
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    cascade={!show_single}
                    showToast={showToast}
                />
            </div>

            <div className='admin-card__container'>
                <TotalHoursCard
                    usr_info={usr_info}
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    cascade={!show_single}
                    showToast={showToast}
                />
            </div>

            <div className='admin-card__container'>
                { has_phishing && 
                <PhishCard
                    usr_info={usr_info}
                    cascade={!show_single}
                    tenant_uid={tenant_uid}
                    showToast={showToast}
                />}
            </div>

            <div className="admin-card__container">
                {has_acceptance &&
                <PolicyCard
                    usr_info={usr_info}
                    cascade={!show_single}
                    tenant_uid={tenant_uid}
                    showToast={showToast}
                />}
            </div>
        </>
    )
}
