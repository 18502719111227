import React, { useEffect, useState } from "react";

import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7OriginalModal } from "../../modals/drip7_original_modal";
import { QueueModal } from "../../modals/admin/queue_modal";
import { QueueTrackModal } from "../../modals/admin/queue_track_modal";

import { ConfirmModal } from "../../modals/confirm_modal";
import { ViewMode, Behavior } from "../../helpers/consts";
import {
    QueueTrackListWidget
} from "../../components/admin/queue_track_list_widget";
import {
    QueueTrackGraphicalWidget
} from "../../components/admin/queue_track_graphical_widget";
import { Drip7Button } from "../../components/drip7_button";
import {Drip7Dropdown} from "../../components/drip7_dropdown";


export const QueueTab = (props) => {
    const { showToast, isPhishing, isPolicy } = props

    const [state, setState] = useState({
        tenant_uid: props.tenant_uid,
        tenants: props.tenants,
        loaded: false,
        show_queue: null,
        show_queue_track: null,
        search: "",
        modes: {},
        queues: [],
        queue_tracks: [],
        selected: {},
        confirm: null,
    })
    const { tenant_uid, tenants, loaded, show_queue, show_queue_track, search, modes, queues, queue_tracks, selected, confirm} = state

    // let tenant = {'subscriptions': []};
    // for (let i = 0; i < tenants.length; i++) {
    //     if (tenants[i].uid == tenant_uid) {
    //         tenant = tenants[i];
    //     }
    // }

    // const { is_msp } = usr_info.tenant
    // const has_content = is_msp || tenant.subscriptions.find(x => x.toLowerCase() == "content") != undefined || true
    // const has_phishing = is_msp || tenant.subscriptions.find(x => x.toLowerCase() == "phishing") != undefined || true
    const tenant = props.tenants.find(x => x.uid == tenant_uid)
    const is_content_module = tenant ? tenant.behavior == Behavior.CONTENT_MODULE : false

    useEffect(() => {
        updateList( props.tenant_uid )
    }, [])

    useEffect(() => {
        if ( tenant_uid != props.tenant_uid ) {
            updateList( props.tenant_uid )
        }
    })

    const updateList = ( tenant_uid ) => {
        Util.fetch_js( "/queue/list/", { tenant_uid, detailed: true, policy_only: isPolicy },
            js => {
                const { queues, queue_tracks } = js;
                const questionQueues = isPhishing? queues.filter(track => track.delivery.toLowerCase()=='phish') : queues.filter(track => track.delivery.toLowerCase()!='phish')

                setState(prev => ({ ...prev,
                    queues:questionQueues,
                    queue_tracks,
                    tenant_uid,
                    loaded: true,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    const filterQueues = (search, queues) => {
        //Do nothing
        if (search == "") {
            return queues;
        }

        //Only keep
        let result = [];
        const search_lc = search.toLowerCase();
        for (let i = 0; i < queues.length; i++) {
            const queue = queues[i];
            if (queue.name.toLowerCase().indexOf(search_lc) >= 0) {
                result.push(queue);
            }
        }

        return result;
    }

    const handleChange = (e) => {
        setState(prev => ({ ...prev,
            [e.target.id]: e.target.value
        }))
    }

    const handleUpdateQueues = (new_queues) => {
        const q_l = {}
        new_queues.forEach( x => { q_l[x.uid] = x })

        setState(prev => ({ ...prev,
            queues: queues.map( x => (x.uid in q_l)? q_l[x.uid]: x  )
        }))
    }

    const handleModalClose = (queue) => {
        updateList( tenant_uid )
        setState(prev => ({ ...prev,
            show_queue: null,
            show_queue_track: null,
        }))
    }

    const handleRemove = () => {
        Util.fetch_js( "/queue/remove/", { queue_uids: Object.keys(selected) },
            js => {
                showToast("Deleted queue(s)", "successful");
                updateList( tenant_uid )
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        setState( prev => ({ ...prev,
            confirm: null,
            selected: {}
        }))
    }

    const confirmAction = (action, msg, yes) => {
        const confirm = {
            action,
            msg: msg,
            yes: yes,
            no: Language.getTitleCase('cancel')
        };
        setState( prev => ({ ...prev,
            confirm
        }))
    }

    const handleShowQueue = (show_queue) => {
        setState( prev => ({ ...prev,
            show_queue
        }))
    }

    const handleShowTrack = ( show_queue_track ) => {
        if ( show_queue_track.uid == "unbound" ) {
            return
        }

        setState( prev => ({ ...prev,
            show_queue_track
        }))
    }

    const handleChangeMode = (e) => {
        const modes = { ...state.modes,
            [e.target.id]: e.target.value,
        }
        setState( prev => ({ ...prev,
            modes
        }))
    }

    const handleDeployAll = () => {
        let payload = {
            queue_uids: Object.keys(selected),
        };

        Util.fetch_js( "/managed/sync_managed/", payload,
            js => {
                showToast(Language.getSentenceCase('deployed'), 'successful');
                onClose(true);
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        setState( prev => ({ ...prev,
            confirm: null,
            selected: {}
        }))
    }
    //Build the queue_tracks from the filtered queues
    let safe_modes = { ...modes }
    let queue_tracks_lookup = { "unbound": { name: "Unbound", uid: "unbound", queues: []} }
    filterQueues(search, queues).forEach( q => {
        let uid = (q.queue_track == null)? "unbound": q.queue_track.uid
        if ( uid in queue_tracks_lookup ) {
            queue_tracks_lookup[uid].queues.push( q )
        }
        else {
            queue_tracks_lookup[uid] = { ...q.queue_track, queues: [q] }
        }

        //Set default safe_modes
        if ( !(uid in safe_modes) ) {
            safe_modes[uid] = ViewMode.GRAPHICAL
        }
    })

    //Ensure the safe modes have unbound listed
    if ( !('unbound' in safe_modes) ) {
        safe_modes['unbound'] = ViewMode.GRAPHICAL
    }

    //Add empty tracks
    if ( search == "" ) {
        queue_tracks.forEach(qt => {
            //Already accounted for, do nothing
            if ( qt.uid in queue_tracks_lookup ) {
                console.log("Dup")
                return
            }

            //Add in a new blank queue track
            queue_tracks_lookup[qt.uid] = { ...qt, queues: [] }

            //Set default safe_modes
            if ( !(qt.uid in safe_modes) ) {
                safe_modes[qt.uid] = ViewMode.GRAPHICAL
            }
        })
    }

    Object.keys(queue_tracks_lookup).forEach(uid => {
        if ( safe_modes[uid] != ViewMode.HISTORIC ) {
            queue_tracks_lookup[uid].queues = queue_tracks_lookup[uid].queues.filter( x => x.active )
        }
        else {
            queue_tracks_lookup[uid].queues = queue_tracks_lookup[uid].queues.filter( x => !x.active )
        }
    })

        //Arrange the queue's
    let qt_keys = Object.values(queue_tracks).sort((a,b) => a.name.localeCompare(b.name)).filter( x => x.uid != 'unbound').map( x => x.uid )
    if ( 'unbound' in queue_tracks_lookup ) {
        qt_keys = ['unbound'].concat( qt_keys )
    }

    const qt_ary = (loaded)? qt_keys.map( uid => queue_tracks_lookup[uid] ): []

    const title = (isPolicy) ?
        Language.getTitleCase('assign policy') :
        ((isPhishing) ? Language.getTitleCase('assign campaign') : Language.getTitleCase('assign curriculum'))

    return (
        <div className='curriculum main-modal'>
            <div className='central__main central__main--management'>
                <div className='central__head'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('Search by curriculum') + '...'}
                            id='search'
                            value={search}
                            onChange={handleChange}
                        />
                    </div>

                    <div className='central__head-button-container'>
                        {Object.keys(selected).length > 0 && (
                            <Drip7Button
                                color='red-500'
                                onClick={() => confirmAction(handleRemove, Language.getTitleCase('delete queues'), Language.getTitleCase('remove')) }>
                                {Language.getTitleCase('remove queues')}
                            </Drip7Button>
                        )}
                        { !isPhishing && !isPolicy && (
                            <Drip7Button
                                onClick={() => handleShowTrack( true )}>
                                {Language.getTitleCase('create track')}
                            </Drip7Button>
                        )}
                        <Drip7Button
                            onClick={() => handleShowQueue( true )}>
                            {title}
                        </Drip7Button>
                        {is_content_module && Object.keys(selected).length > 0 &&
                            <Drip7Button
                                onClick={() => confirmAction(handleDeployAll, Language.getSentenceCase('deploy to all managed tenants?'), Language.getSentenceCase('yes'))}>
                                {Language.getTitleCase('deploy to managed tenants')}
                            </Drip7Button>
                        }
                    </div>
                </div>

                {Object.entries(qt_ary).map(([key, queue_track]) => (
                    <div key={`queue_track_wrapper-${key}`}
                         className="queue-track-wrapper m-8">
                        {!isPolicy &&
                        <div className="queue-track-header">
                            {queue_track.uid != "unbound" &&
                            <label className="queue-track-header--item"
                                   onClick={() => handleShowTrack( queue_track )}>
                                {queue_track.name}
                            </label>
                            }

                            <Drip7Dropdown
                                id={queue_track.uid}
                                entries={Object.values(ViewMode)}
                                value={safe_modes[queue_track.uid]}
                                onChange={handleChangeMode}
                                display={x => Language.getTitleCase(x)}
                                overflow="max-h-60"
                                />
                        </div>
                        }

                        {(safe_modes[queue_track.uid] == ViewMode.LIST || safe_modes[queue_track.uid] == ViewMode.HISTORIC) &&
                            <QueueTrackListWidget
                                queues={queue_track.queues}
                                selected={selected}
                                onChange={handleChange}
                                onShowQueue={handleShowQueue}
                                />
                        }

                        {safe_modes[queue_track.uid] == ViewMode.GRAPHICAL &&
                            <QueueTrackGraphicalWidget
                                queue_track={queue_track}
                                queues={queue_track.queues}
                                selected={selected}
                                isPolicy={isPolicy}
                                showToast={showToast}
                                onChange={handleChange}
                                onRefresh={() => updateList( props.tenant_uid )}
                                onShowQueue={handleShowQueue}
                                onUpdateQueues={handleUpdateQueues}
                            />
                        }

                        {queue_track.queues.length == 0 &&
                            <div className="text-center mt-6">
                                <h3 className="mt-2 text-sm font-semibold text-gray-900">No queues</h3>
                                <p className="mt-1 text-sm text-gray-500">Get started by assigning a new queue.</p>
                                <div className="my-6">
                                    <Drip7Button
                                        onClick={() => handleShowQueue( true )}>
                                        {!isPolicy? Language.getTitleCase('assign curriculum'): Language.getTitleCase('assign policy')}
                                    </Drip7Button>
                                </div>
                            </div>
                        }
                    </div>
                ))}

                {confirm != null && (
                    <ConfirmModal
                        msg={confirm.msg}
                        yesTxt={confirm.yes}
                        noTxt={confirm.no}
                        onYes={() => confirm.action(true)}
                        onNo={() => setState(prev => ({...prev, confirm: null}))}
                    />
                )}
            </div>

            <Drip7OriginalModal>
                {show_queue != null && (
                    <QueueModal
                        queue={show_queue}
                        tenant_uid={tenant_uid}
                        is_content_module={is_content_module}
                        showToast={showToast}
                        onClose={handleModalClose}
                        phishQueue={isPhishing}
                        policyQueue={isPolicy}
                    />
                )}

                {show_queue_track != null && (
                    <QueueTrackModal
                        queue_track={show_queue_track}
                        tenant_uid={tenant_uid}
                        showToast={showToast}
                        onClose={handleModalClose}
                    />
                )}
            </Drip7OriginalModal>
        </div>
    );
}
