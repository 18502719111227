import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";

import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { ConfirmModal } from "../../modals/confirm_modal";
import NonBadgePlaceholder1 from "../../assets/images/badges/NonBadgePlaceholder1.jpg"

import { Badge } from "../badge";

export const BadgePopup = props => {
    const { badge_uid, onClose, showToast } = props;
    const [state, setState] = useState({
        badge: { ...props.badge, ...props.badge.badge },
        confirm: null,
        contents: {},
        collections: {}
    });

    const { badge, confirm, contents, collections } = state;
    const { uid, name, percentage, owned_perc, image, group, assigned_on, total, progress,completed } = badge;
    let { points } = badge;
    points = points || 0

    const navigate = useRef( useNavigate() )

    // Load all the avatars
    useEffect(() => {
        Util.fetch_js( "/client/badge/details/", { badge_uid },
            badge => {
                badge = {...badge, ...badge.badge}

                let collection;
                if (badge.collections.length > 0) {
                    collection = badge.collections[0]
                } else {
                    collection = ''
                }

                setState(prev => ({...prev,
                    badge,
                    contents: badge.contents[0],
                    collections: collection
                }))
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    }, []);

    const handleContinue = () => {
        navigate.current(`/drip?badge_uid=${badge.uid}`);
    };

    const handleRestart = () => {
        Util.fetch_js( "/client/badge/restart/", { badge_uid: badge.uid },
            js => {
                handleContinue();
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        setState(prev => ({...prev,
            confirm: null
        }))
    };

    const confirmAction = action => {
        let yes = null;
        let msg = null;
        if (action == handleRestart) {
            yes = Language.getTitleCase('restart');
            msg = Language.getSentenceCase('Restart this badge?');
        }
        else {
            throw "Program error, Unknown action: " + action;
        }

        //Setting a confirm action, will load message box, and if accepted saves
        const new_confirm = { action, msg, yes, no: Language.getSentenceCase('cancel') };
        setState({ ...state, confirm: new_confirm });
    };

    let perc = parseInt(percentage) || 0;
    if (perc < 0) {
        perc = 0
    }
    if (perc > 100) {
        perc = 100;
    }

    let op = Number(owned_perc * 100.0).toFixed(1)

    const t = perc / 100.0
    const prog_first = "rotate(" + (t <= 0.5 ? t * 360 : 180) + "deg)";
    const prog_second = "rotate(" + t * 360 + "deg)";

    // const badgeMessage = () => {
    //     let text;
    //     let lang
    // }

    const badgeCriteria = () => {
        let num;
        let progress;

        if (collections.params.points) {
            num = collections.params.points
        } else {
            num = collections.params.count
        }

        progress = Math.round(num * t)
        return progress + '/' + num
    }

    return (
        <div className='up-popup__container' onClick={onClose}>
            <div className='up-popup up-popup--badge-info'>
                <div className='up-popup__icon-wrapper'>
                    <div className='up-popup__icon-wrapper--cropped'></div>
                </div>

                <div className='up-popup__progress'>
                    <div className='up-popup__progress-meter'>
                        <div
                            style={{ transform: prog_first }}
                            className='radial-progress__half-circle radial-progress__half-circle--first-50'></div>
                        {perc > 50 && (
                            <div
                                style={{ transform: prog_second }}
                                className='radial-progress__half-circle radial-progress__half-circle--second-50'></div>
                        )}
                        <div className='radial-progress__half-circle-cover'></div>
                        <img className='up-popup__icon' alt='badge' src={(badge.completed || !badge.obscure) ? image : NonBadgePlaceholder1 } />
                    </div>
                </div>

                <div className={`up-popup__head ${ contents.message == '' || !contents.message ? '' : 'message'}`}>
                    <h2 className='up-popup__badge-name'>{name}</h2>
                    {assigned_on > 0 && (
                        <h4 className="up-popup__badge-earned">
                            {Language.getSentenceCase('Earned on')}: {Util.epochToDate(assigned_on)}
                        </h4>)}

                    {contents.message && (
                        <div className='up-popup__badge-message'>
                            {completed ? Language.getSentenceCase('Earned') : contents.message }
                        </div>)}
                </div>
                <div className='up-popup__badge-stats'>
                    <div className='up-popup__badge-stats-percentage'>
                        <h2>{percentage}%</h2>
                        <h4>{Language.getSentenceCase('completed')}</h4>
                    </div>

                    {collections.params && (<div className='up-popup__badge-stats-points'>
                        <h2>{badgeCriteria()}</h2>
                        <h4>{collections.criteria}</h4>
                    </div>)}
                </div>

                <div className='up-popup__badge-stats'>
                    <div className='up-popup__badge-stats-percentage'>
                        <h2>{Math.round(owned_perc * 100.0)}%</h2>
                        <h4>{Language.getSentenceCase('Players have this achievement')}</h4>
                    </div>

                    <div className='up-popup__badge-stats-points'>
                        {points > 0 && <>
                            <h2>+{points}</h2>
                            <h4>{Language.getTitleCase('Extra Points')}</h4>
                        </>}
                    </div>
                </div>


                {false && (
                    <div className='up-popup__buttons'>
                        {percentage > 0 && (
                            <button
                                className='up-popup__button up-popup__button--restart'
                                onClick={() => confirmAction(handleRestart)}>
                                {Language.getSentenceCase('restart')}
                            </button>
                        )}
                        {percentage < 100 && (
                            <button
                                className='up-popup__button up-popup__button--continue'
                                onClick={handleContinue}>
                                {Language.getSentenceCase('continue')}
                            </button>
                        )}
                    </div>
                )}
            </div>

            {confirm != null && (
                <ConfirmModal
                    msg={confirm.msg}
                    yesTxt={confirm.yes}
                    noTxt={confirm.no}
                    onYes={() => confirm.action(true)}
                    onNo={() => setState({ ...state, confirm: null })}
                />
            )}
        </div>
    );
};
